import React, { Suspense, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import { getPosition2ContainerMap } from "../../util/UtilReact";

import AfdSelectorSimple from "../../selector/AfdSelectorSimple";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";

import GetMenuPageBanner from "../../util/GetMenuPageBanner";
import AfdMainpageBanner from "../AfdMainpageBanner";
import ISelectorPosition from "../../type/ISelectorPosition";
import ConfigButtonFactory from "../../examples/Configurator/ConfigButtonFactory";
import { setOpenConfigurator } from "../../redux_reducer/util/dispatchShortcut";
import SoftAvatar from "../../components/SoftAvatar";
import Icon from "@mui/material/Icon";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { useNavigate } from "react-router-dom";
import linkDictionary from "../../linkDictionary";
import CtxSiteBlog, { defaultContext } from "../../context/CtxSiteBlog";

/*import React from "@types/react";*/


function RtSiteBlog({
                      menuItemObj,
                      containerObjList,
                      userInfoObj,
                      routeCategory,
                      redirectionObj,
                    }: IPropGantry) {


  var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
    menuItemObj,
    containerObjList,
    userInfoObj,
    routeCategory,
    redirectionObj,
  );

  const [elementMenuItemRowObj, setElementMenuItemRowObj] = useState(defaultContext.elementMenuItemRowObj);
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);

  const position2ContainerMap = getPosition2ContainerMap(containerObjList);

  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  function getGalleryImageListButtonTSX() {
    return <CollectionsOutlinedIcon
      sx={{
        /*color: "primary.main",*/
      }}
    />;
  }

  const navigate = useNavigate();
  const handleConfiguratorOpen = () => {
    navigate(linkDictionary.CLUB_GALLERY_NEW_IMAGE);

  };
  let configButtonTSX = ConfigButtonFactory(
    handleConfiguratorOpen,
    getGalleryImageListButtonTSX(),
  );

  const getSelectorPositionJSX = function(positionCode: ISelectorPosition): JSX.Element | null {
    if (position2ContainerMap[positionCode]?.length > 0) {
      return (
        <Container>
          <AfdSelectorSimple
            key={positionCode}
            menuItemObj={menuItemObj}
            userInfoObj={userInfoObj}
            position={positionCode}
            redirectionObj={redirectionObj}
            containerAssocList={position2ContainerMap[positionCode]}
          /></Container>);
    } else {
      return null;
    }

  };

  var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;
  return (
    <CtxSiteBlog.Provider value={{
      elementMenuItemRowObj: elementMenuItemRowObj,
      setElementMenuItemRowObj: setElementMenuItemRowObj,
    }}>
      <PageLayout
      >
        <>
          {getSelectorPositionJSX("top-a")}
          {getSelectorPositionJSX("showcase-a")}
        </>
        {/*2: reszta zaraz pod spodem */}
        <SoftBox
          /*mt={1 * containerMarginTop}*/
          position={"relative"}
          px={{ xs: 1, sm: 0 }}
          mt={containerMarginTop}
        >

          {/*// @ts-ignore*/}

          {/*<Header tabValue={tabValue} tabHandler={handleSetTabValue} />*/}
          {getSelectorPositionJSX("maintop-a")}
          {getSelectorPositionJSX("sidebar-a")}
          {getSelectorPositionJSX("sidebar-b")}

          {getSelectorPositionJSX("content-top-a")}
          {getSelectorPositionJSX("body-no-image")}
          {getSelectorPositionJSX("body")}
          {getSelectorPositionJSX("content-bottom-a")}
          {getSelectorPositionJSX("mainbottom-a")}
          {getSelectorPositionJSX("bottom-a")}
          {getSelectorPositionJSX("module-footer-a")}
          {/*// @ts-ignore*/}

          {userInfoObj.user_id > 0 && menuItemObj.id == 1
            ?
            configButtonTSX : null}
        </SoftBox>
      </PageLayout>
    </CtxSiteBlog.Provider>
  );
}

export default RtSiteBlog;
