import {createContext} from "react";
import IAppObj from "../type/context/IAppObj";
import IElementGalleryCommentLine from "../type/gallery/IElementGalleryCommentLine";

import ICtxGalleryDashboard from "../type/context/ICtxGalleryDashboard";
import ICtxClubDouble from "../type/context/ICtxClubDouble";
import { IElementMenuItemRow } from "../component/controller/get_site_blog_menu/GetSiteBlogMenu";
/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */
//IElementMenuItemRow
export interface ICtxSiteBlog {
    elementMenuItemRowObj: IElementMenuItemRow;
    setElementMenuItemRowObj: React.Dispatch<React.SetStateAction<IElementMenuItemRow>>;
}

export const defaultContext: ICtxSiteBlog = {
    elementMenuItemRowObj: {
        menu_id: -1,
        menu_name: "",
        menutype: "",
        menu_url: "",
        ordering: -1,
    },
    setElementMenuItemRowObj: () => {},
};
const CtxSiteBlog = createContext<ICtxSiteBlog>(defaultContext);
//const AppObjContext = createContext(null);

export default CtxSiteBlog;
