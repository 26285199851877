import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom"; // npm i react-router-dom --save
import AfdContainer from "../component/AfdContainer";
import IContainerQuery from "../type/IContainerQuery";
import IParamObj from "../type/IParamObj";
import ISelector from "../type/ISelector";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import RestApiAmocourseService from "../service/restapi/RestApiAmocourseService";
import {debugLog, debugDir} from "../util/UtilReact";

import AfdLoadingFullpage from "../component/AfdLoadingFullpage";
import {processContainer, processElement, setCalculateTotal} from "../util/UtilReact";
import RestApiAuthorizationService from "../service/restapi/RestApiAuthorizationService";
import IAmocourseTotalArray from "../type/amocourse/IAmocourseTotalArray";
import IAmocoursePriceInfo from "../type/amocourse/IAmocoursePriceInfo";
import CtxAmocourse from "../context/CtxAmocourse";
import IRQOutputList from "../type/rq/IRQOutputList";
import IGalleryImageInfo from "../type/gallery/IGalleryImageInfo";
/* take the container list and slect those which are  relevant for this position *
 */
const AfdSelectorSimple = ({
                               menuItemObj: menuItemObj,
                               userInfoObj: userInfoObj,
                               position: position,
                               containerAssocList: containerAssocList,
                               redirectionObj: redirectionObj,
                               formikGantry: formikGantry,
                               setFormikParamObj: setFormikParamObj
                           }: ISelector) => {

    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();



    //https://stackoverflow.com/questions/68042569/how-can-i-get-the-params-from-the-url-with-react
    function setParamObjSwitch(queryHookObj: any) {
        /*query.forEach(function(value: string, key: string) {
            debugLog(value, key);
        });*/

        let targetPosition: number;
        // TODO - temp vars
        let isMobile = 0;
        let imageId: number = 0;
        let isImageSelected: number = 0;
        let xx_mainpage_message_dim: "MESSAGE_CONSTRAINT" | "MESSAGE_WHO_WOULD" | "MESSAGE_BUSYMUM" | "MESSAGE_READY_FOR" | "MESSAGE_IMAGINE_ALBUM" | "MESSAGE_IMAGINE_SYMBOL" = "MESSAGE_CONSTRAINT";
        let itemMainpageImageId: number = 0;

        if (menuItemObj.id == 1) {
            if (position === 'position-mainpage-welcome' ||
                position === 'position-mainpage-strip' ||
                position === 'position-mainpage-toolbar' ||
                position === 'position-mainpage-video' ||
                position === 'position-mainpage-social'
            ) {
                setParamObj({
                        position_param0: isMobile.toString(),
                        position_param1: imageId.toString(),
                        position_param2: isImageSelected.toString(),
                        position_param3: xx_mainpage_message_dim
                    }
                );
            }
            if (position === 'body' || position === 'body-no-image') {
                setParamObj({
                        position_param0: isMobile.toString(),
                        position_param1: null,
                        position_param2: itemMainpageImageId.toString(),
                    }
                );
            }


        } else if (menuItemObj.id == 35) {
            if (position === 'body') {
                if (redirectionObj?.menu_item_id) {
                    promiseArray.push(RestApiService.getPromise(RestApiCommonService.getSelectorCourseDescUrl(
                        redirectionObj?.menu_item_id,
                        redirectionObj?.object_desc_category_id
                    )));
                }
            }
            if (position === 'position-mainpage-welcome') {
                if (redirectionObj?.menu_item_id) {
                    promiseArray.push(RestApiService.getPromise(RestApiCommonService.getSelectorCourseDescUrl(
                        redirectionObj?.menu_item_id,
                        redirectionObj?.object_desc_category_id
                    )));
                }
                setParamObj(
                    {
                        position_param0: isMobile.toString(),
                        position_param1: imageId.toString(),
                        position_param2: isImageSelected.toString(),
                        position_param3: null
                    }
                );
            }
        } else if (menuItemObj.id == 11) {
            setParamObj({
                position_param0: queryHookObj.get('user_id')
            });
        } else if (menuItemObj.id == 14) {
            setParamObj({
                position_param0: queryHookObj.get('is_reg_start')
            });
        } else if (menuItemObj.id == 40) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param0: queryHookObj.get('f'),
                    position_param1: queryHookObj.get('t'),
                    position_param2: queryHookObj.get('Itemid'),
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('f'),
                    position_param1: queryHookObj.get('t'),
                });
            }

        } else if (menuItemObj.id == 52) {
            if (position === 'mainbottom-a' ||
                position === 'body'

            ) {
                setParamObj({
                    position_param0: queryHookObj.get('dict_code')
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('mid'),
                    position_param1: queryHookObj.get('promo')
                });
            }

        } else if (menuItemObj.id == 71) {
            let urlUser = queryHookObj.get('user');
            let email_enc = queryHookObj.get('enc');
            let Itemid = queryHookObj.get('Itemid');
            let task = queryHookObj.get('task');
            let tab = queryHookObj.get('tab');

            let url_user_id: string  = urlUser > 0 ? urlUser : userInfoObj.user_id;
                setParamObj({
                    position_param0: url_user_id,
                    position_param1: task,
                });
                // TODO
        } else if (menuItemObj.id == 86) {
            if (position === 'body') {
                setParamObj({
                    position_param8: queryHookObj.get('mid'),
                });
            }
        } else if (menuItemObj.id == 120) {
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('mid'),
                    position_param1: queryHookObj.get('promo'),
                    position_param8: queryHookObj.get('mid'),
                });
            }
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param0: queryHookObj.get('instructor_id')
                });
            }
        } else if (menuItemObj.id == 122) {
            setParamObj({
                position_param0: queryHookObj.get('f'),
                position_param1: queryHookObj.get('t')
            });
            targetPosition = 809;
        } else if (menuItemObj.id == 125) {
            if (position === 'bottom-a') {
                setParamObj({
                    position_param0: queryHookObj.get('is_price_only'),
                    position_param1: queryHookObj.get('url_category_id')
                });
            }
            if (position === 'content-bottom-a') {
                setParamObj({
                    position_param0: queryHookObj.get('mid'),
                    position_param1: queryHookObj.get('promo')
                });
            }
        } else if (menuItemObj.id == 128) {
            setParamObj({
                position_param0: userInfoObj.usertype,
            });
        } else if (menuItemObj.id == 133) {
            if (position === 'showcase-a') {
                /*null,
                  null,
                  $wp_term_id,
                  null,
                  null,
                  $row_term['xx_template_row_module'],
                  $row_term['term_name']*/
                setParamObj({
                    position_param2: queryHookObj.get('wp_term_id'),
                    position_param5: queryHookObj.get('xx_template_row_module'),
                    position_param6: queryHookObj.get('term_name'),
                });
            }

        } else if (menuItemObj.id == 137) {
            /* w wersji REACT informacje na temat CONTENT pobiera w CONTAINER */
                setParamObj({
                    position_param0: queryHookObj.get('wp_post_id'),
                });
        } else if (menuItemObj.id == 155) {
                setParamObj({
                    position_param0: queryHookObj.get('redir_code'),
                    position_param1: queryHookObj.get('redir_menu_item_id'),
                    position_param2: queryHookObj.get('redir_redirection_id'),
                    position_param3: queryHookObj.get('redir_oldurl'),
                    position_param4: queryHookObj.get('redir_newurl'),
                    position_param5: queryHookObj.get('redir_menu_name'),
                });
        } else if (menuItemObj.id == 160) { //sidebar-b only
            setParamObj({
                position_param0: queryHookObj.get('f'),
                position_param1: queryHookObj.get('t')
            });
            targetPosition = 809;
        } else if (menuItemObj.id == 163) {
                setParamObj({
                    position_param0: queryHookObj.get('instructor'),
                    position_param1: queryHookObj.get('username'),
                    position_param2: queryHookObj.get('interval')
                });
        } else if (menuItemObj.id == 184) {
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('u'),
                    position_param2: queryHookObj.get('course_category_id')
                });
            }
        } else if (menuItemObj.id == 191) {
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('f'),
                    position_param1: queryHookObj.get('t')
                });
                targetPosition = 809;
            }
        } else if (menuItemObj.id == 203) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param0: queryHookObj.get('ls'),
                    position_param1: queryHookObj.get('le')
                });
            }
        } else if (menuItemObj.id == 207) {
            if (position === 'sidebar-b') {
                setParamObj({
                    position_param8: queryHookObj.get('mid'),
                });
            }
        } else if (menuItemObj.id == 225) {
            promiseArray.push(
                RestApiService.getPromise(RestApiCommonService.getFunctionSelectResultUrl(
                        'get_user_id_from_email_enc',
                        queryHookObj.get('enc'),
                        null
                    )
                )
            );
                //get_selector_newsletter_rating('[[POSITION]]');
        } else if (menuItemObj.id == 233) {
                setParamObj({
                    position_param0: queryHookObj.get('mid'),
                    position_param1: queryHookObj.get('promo'),
                    position_param8: queryHookObj.get('mid'),

                });
        } else if (menuItemObj.id == 253) {
            if (position === 'body') {
                setParamObj({
                    position_param8: queryHookObj.get('mid'),
                });
            }
        } else if (menuItemObj.id == 260) {
                setParamObj({
                    position_param0: queryHookObj.get('instructor'),
                    position_param1: queryHookObj.get('category_id'),
                    position_param2: queryHookObj.get('year'),
                    position_param3: queryHookObj.get('mode'),
                    position_param4: queryHookObj.get('interval'),
                    position_param5: queryHookObj.get('username')
                });
        } else if (menuItemObj.id == 275) {
            if (position === 'sidebar-a') {

                let forum_id = 496;
                let ord = 'created desc';

                setParamObj({
                    position_param0: forum_id.toString(),
                    position_param1: queryHookObj.get('ls'),
                    position_param2: queryHookObj.get('le'),
                    position_param3: ord.toString(),
                });
            }
        } else if (menuItemObj.id == 276) {
            if (position === 'maintop-a') {
                setParamObj({
                    position_param8: queryHookObj.get('mid'),
                });
            }
        } else if (menuItemObj.id == 284) {
            if (position === 'body') {
                setParamObj({
                    position_param8: queryHookObj.get('mid'),
                });
            }
        } else if (menuItemObj.id == 331) {
                setParamObj({
                });
        } else if (menuItemObj.id == 391) {
            let ord = 'created desc';

            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('url_user_id'),
                    position_param3: ord.toString(),
                });
            }
            if (position === 'content-top-a' ||
                position === 'content-bottom-a' ||
                position === 'body' ||
                position === 'sidebar-a'
            ) {
                setParamObj({
                    position_param0: '496',
                    position_param3: ord.toString(),
                });
            }
        } else if (menuItemObj.id == 392) {
            if (position === 'content-top-a') {
                //get_selector_site_voucher();
            }
        } else if (menuItemObj.id == 462) {
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('bg_id'),
                });
            }
        } else if (menuItemObj.id == 488) {
            /* Site RESET */
                setParamObj({
                    position_param0: queryHookObj.get('enc'),
                    position_param1: queryHookObj.get('u'),
                    position_param2: queryHookObj.get('key'),
                });
        } else if (menuItemObj.id == 498) {
            if (position === 'body') {
                let regId = queryHookObj.get('reg_id');
                setParamObj({
                    position_param0: regId,
                });
                /* dane CONFIRM pobierane tutaj ponieważ wykorzystywane przez wiele CONTROLLERs */
                if (regId) {
                    promiseArray.push(
                        RestApiService.getPromise(RestApiAmocourseService.getAmocourseFormConfirmProviderAssoc(
                                regId
                            )
                        )
                    );
                }
            }
        } else if (menuItemObj.id == 507) {
            setParamObj({
                position_param0: queryHookObj.get('user_id'),
            });
        } else if (menuItemObj.id == 543) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param0: queryHookObj.get('t'),
                    position_param1: queryHookObj.get('f'),
                    position_param2: queryHookObj.get('Itemid'),
                });
            }
        } else if (menuItemObj.id == 544) {
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('instructor')
                });
            }
        } else if (menuItemObj.id == 603) {
            /*BUGFIX: Tylko dla BODY - nie przeładowujemy XM/XT dla każdej pozycji*/
            if (position === 'body') {
                let postXtRefershObj = {
                    xm_materialized_user_refresh: "1",
                    procedure_name: 'set_materialized_xt_course_event_2_user_v',
                    object_id: userInfoObj.user_id,
                }

                promiseArray.push(
                    RestApiService.getPromise('',
                        "POST",
                        RestApiCommonService.setXmMaterializedUserRefresh(
                            postXtRefershObj,
                        )
                    )
                );
            }

        } else if (menuItemObj.id == 612) {
            var chat_calendar_id = queryHookObj.get('chat_calendar_id');
            var message = queryHookObj.get('message');
            var subscriber_id = queryHookObj.get('sid');
            var selected_email_hex = queryHookObj.get('selected_email');

            if (position === 'content-top-a') {
                setParamObj({
                    position_param0: queryHookObj.get('chat_calendar_id'),
                    position_param3: message,
                    position_param10: queryHookObj.get('welcome'),
                });
            }
            if (position === 'position-mainpage-welcome') {
                setParamObj({
                    position_param0: null,
                    position_param4: message,
                });
            }
            if (position === 'body') {
                setParamObj({
                    position_param0: null,
                    position_param1: null,
                    position_param2: selected_email_hex,
                    position_param3: message,
                    position_param4: chat_calendar_id,
                    position_param5: null,
                    position_param6: subscriber_id,
                    position_param7: message,
                });
            }
        } else if (menuItemObj.id == 617) {
            if (position === 'sidebar-b') {
                setParamObj({
                    position_param0: queryHookObj.get('ls'),
                    position_param1: queryHookObj.get('user_id'),
                    position_param2: queryHookObj.get('mode'),
                    position_param3: queryHookObj.get('category_set'),
                });
            }
        } else if (menuItemObj.id == 629) {
              /*  setParamObj({
                    position_param0: queryHookObj.get('customer_email'),
                    position_param1: queryHookObj.get('system_user_id'),
                });
*/              if (queryHookObj.get('email_encoded'))  {
                promiseArray.push(
                  RestApiService.getPromise(RestApiCommonService.getFunctionSelectResultUrl(
                      'email_decode',
                      queryHookObj.get('email_encoded'),
                      null
                    )
                  )
                );
            }

        } else if (menuItemObj.id == 657) {
            if (position === 'feature-a') {
                /*??? TODO launch_all_page_functions('xxx', '[[POSITION]]'); ?]]{/source}*/
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: queryHookObj.get('f'),
                    position_param1: queryHookObj.get('t'),
                });
                targetPosition = 809;
            }
        } else if (menuItemObj.id == 669) {
            if (position === 'content-top-a' ||
                position === 'body' ||
                position === 'mainbottom-a'
            ) {
                setParamObj({
                    position_param0: queryHookObj.get('message_id'),
                    position_param1: queryHookObj.get('chat_calendar_id'),
                    position_param2: queryHookObj.get('action'),
                });
            }
        } else if (menuItemObj.id == 692) {

            if (position === 'content-top-a') {
                /* Pobierz z kontekstu i zwróć do PARAM  */
                setParamObj({
                    position_param0: (redirectionObj?.object_user_id ? redirectionObj.object_user_id.toString() : "-1"),
                    position_param1: (userInfoObj.user_id > 0 ? "1" : "0"),
                    position_param2: queryHookObj.get('is_story_only'),
                    position_param3: queryHookObj.get('is_brief'),
                    position_param4: queryHookObj.get('message_id'),
                });
            }
            if (position === 'content-bottom-a') {
                setParamObj({
                    position_param0: (redirectionObj?.object_user_id ? redirectionObj.object_user_id.toString() : "-1"),
                    position_param1: (userInfoObj.user_id > 0 ? "1" : "0"),
                    position_param2: queryHookObj.get('is_story_only'),
                    position_param3: queryHookObj.get('is_brief'),
                    position_param4: queryHookObj.get('message_id'),
                });
            }
        } else if (menuItemObj.id == 695) {
            if (position === 'content-bottom-a') {
                setParamObj({
                    position_param0: queryHookObj.get('system_user_id'),
                });
            }
            if (position === 'content-top-a' ||
                position === 'maintop-a' ||
                position === 'sidebar-a'
            ) {
                /* Pobierz z kontekstu i zwróć do PARAM  */
                setParamObj({
                    position_param0: queryHookObj.get('system_user_id'),
                });
            }
        } else if (menuItemObj.id == 742) {
            if (position === 'body') {
                setParamObj({
                    position_param0: queryHookObj.get('chat_calendar_id')
                });
            }
        } else if (menuItemObj.id == 761) {
            setParamObj({
                position_param0: queryHookObj.get('category_id'),
            });
        } else if (menuItemObj.id == 767) {
            if (position === 'maintop-a') {
                setParamObj({
                    position_param0: String(userInfoObj.user_id)
                });
            }
        } else if (menuItemObj.id == 806) {
            if (position === 'body') {
                promiseArray.push(
                    RestApiService.getPromise(RestApiCommonService.getFunctionSelectResultUrl(
                            'email_decode',
                            queryHookObj.get('email_encoded'),
                            null
                        )
                    )
                );
            }
        } else if (menuItemObj.id == 809) {
                promiseArray.push(
                    RestApiService.getPromise(RestApiCommonService.getFunctionSelectResultUrl(
                            'get_course_uni_semester_forum_id',
                            queryHookObj.get('f'),
                            null
                        )
                    )
                );
        } else if (menuItemObj.id == 810) {
            setParamObj({
                position_param0: queryHookObj.get('f'),
                position_param1: queryHookObj.get('t'),
                position_param2: queryHookObj.get('real_forum_id'),
                position_param3: queryHookObj.get('l'),
                position_param4: queryHookObj.get('a'),
                position_param5: queryHookObj.get('h'),
                position_param6: queryHookObj.get('d'),
                position_param7: queryHookObj.get('i'),
                position_param8: queryHookObj.get('real_forum_id')
            });
        } else if (menuItemObj.id == 868) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param8: queryHookObj.get('mid')
                });
            }

        } else if (menuItemObj.id == 120) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param1: queryHookObj.get('instructor_id')
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param1: queryHookObj.get('mid'),
                    position_param2: queryHookObj.get('promo')
                });
            }
        } else if (menuItemObj.id == 120) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param1: queryHookObj.get('instructor_id')
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param1: queryHookObj.get('mid'),
                    position_param2: queryHookObj.get('promo')
                });
            }

        } else if (menuItemObj.id == 120) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param1: queryHookObj.get('instructor_id')
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param1: queryHookObj.get('mid'),
                    position_param2: queryHookObj.get('promo')
                });
            }
        } else if (menuItemObj.id == 120) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param1: queryHookObj.get('instructor_id')
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param1: queryHookObj.get('mid'),
                    position_param2: queryHookObj.get('promo')
                });
            }
        } else if (menuItemObj.id == 120) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param1: queryHookObj.get('instructor_id')
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param1: queryHookObj.get('mid'),
                    position_param2: queryHookObj.get('promo')
                });
            }
        } else if (menuItemObj.id == 120) {
            if (position === 'mainbottom-a') {
                setParamObj({
                    position_param1: queryHookObj.get('instructor_id')
                });
            }
            if (position === 'maintop-a') {
                setParamObj({
                    position_param1: queryHookObj.get('mid'),
                    position_param2: queryHookObj.get('promo')
                });
            }
        } else {
        }


        /*
                switch(menuItemObj.id) {

                    case 120:
                        if (position === 'mainbottom-a') {
                            setParamObj({
                                position_param1: queryHookObj.get('instructor_id')
                            });
                            isDataReady = true;
                        }
                        if (position === 'maintop-a') {
                            setParamObj({
                                position_param1: queryHookObj.get('mid'),
                                position_param2: queryHookObj.get('promo')
                            });
                            isDataReady = true;
                        }
                        break;
                    case 122:
                        setParamObj({
                            position_param1: queryHookObj.get('f'),
                            position_param2: queryHookObj.get('t')
                        });
                        targetPosition = 809;
                        isDataReady = true;
                        break;
                    default:
                        /!* for other components set value so it is not undefined and we can go further*!/
                        /!*setParamObj({})*!/
                        isDataReady = true;
                }*/
    }


    var promiseArray: Promise<any>[] = [];

    const [paramObj, setParamObj] = useState<IParamObj>({});
    const [isDataReady, setIsDataReady] = useState(false);

    /* After there is promiseArray - execute */
    useEffect(() => {

            setParamObjSwitch(queryHookObj);

            if (promiseArray.length > 0) {
                Promise.all(
                    promiseArray
                ).then(
                    (responseArray) => {
                        if (menuItemObj.id == 35) {

                            debugLog("Course DESC info retrieved: ");
                            debugDir(responseArray[0].data);
                            if (position === 'body') {
                                setParamObj(
                                    {
                                        position_param0: responseArray[0].data.category_id,
                                        position_param1: responseArray[0].data.is_workshop,
                                        position_param2: responseArray[0].data.course_rule_code,
                                        position_param3: responseArray[0].data.chapter_part_count,
                                        position_param4: responseArray[0].data.uni_semester,
                                        position_param5: responseArray[0].data.invitation_part_path,
                                        position_param6: responseArray[0].data.forum_id,
                                        position_param7: responseArray[0].data.is_course,
                                        position_param8: responseArray[0].data.is_new_service,
                                        position_param9: responseArray[0].data.welcome,
                                        position_param10: responseArray[0].data.uni_catalog_forum_id
                                    });
                            } else if (position === 'position-mainpage-welcome') {
                                setParamObj(
                                    (prevParamObj) => {
                                        return {
                                            ...prevParamObj,
                                            position_param4: responseArray[0].data.description,
                                            position_param5: responseArray[0].data.course_promo_text
                                        }
                                    });
                            }
                            setIsDataReady(true);
                        }
                        if (menuItemObj.id == 498) {
                            setParamObj(
                                (prevParamObj) => {
                                    return {
                                        ...prevParamObj,
                                        paramRegConfirmObj: responseArray[0].data,
                                    }
                                });
                            setIsDataReady(true);
                        }
                        /* Refresh XT */
                        if (menuItemObj.id == 603) {
                            setIsDataReady(true);
                        }
                    }
                );
            } else {
                setIsDataReady(true);
            }
        },
        /* rerender after page change */
        [
            menuItemObj.id,
            (redirectionObj?.object_desc_category_id ? redirectionObj.object_desc_category_id : -1), // w przypadku 35 ta sama strona, inne opisy
        ]
    );

    /* Recalculate PARAMS if */
    useEffect(() => {

        if (promiseArray.length > 0) {
            Promise.all(
                promiseArray
            ).then(
                (responseArray) => {
                    if (menuItemObj.id == 225) {
                        let internaResponseObj = (responseArray[0].data ? responseArray[0].data : []);
                        setParamObj({
                            position_param0: internaResponseObj.function_select,
                            position_param1: queryHookObj.get('enc'),
                            position_param2: queryHookObj.get('message_id'),
                            position_param3: queryHookObj.get('rating_status'),
                            position_param4: queryHookObj.get('sid'),
                            position_param5: internaResponseObj.function_select,
                            position_param9: queryHookObj.get('welcome'),
                        });
                        setIsDataReady(true);
                    }
                    if (menuItemObj.id == 629
                        || menuItemObj.id == 806
                    ) {
                        let internaResponseObj = (responseArray[0].data ? responseArray[0].data : []);
                        setParamObj(
                            (prevParamObj: IParamObj) => {
                                return {
                                    ...prevParamObj,
                                    position_param0: queryHookObj.get('email_encoded'),
                                    position_param1: queryHookObj.get('system_user_id'),
                                    position_param2: internaResponseObj.function_select.trim().replace("\u0000", "").replace("\u0000", ""),
                                }
                            });
                        setIsDataReady(true);
                    }
                    if (menuItemObj.id == 809) {
                        let internaResponseObj = (responseArray[0].data ? responseArray[0].data : []);
                        setParamObj({
                            position_param0: queryHookObj.get('f'),
                            position_param1: queryHookObj.get('t'),
                            position_param2: queryHookObj.get('real_parent_forum_id'),
                            position_param7: internaResponseObj.function_select.trim().replace("\u0000", "").replace("\u0000", ""),
                        });
                        setIsDataReady(true);
                    }

                }
            );

        }
    }, [menuItemObj.id,
        (formikGantry ? formikGantry.values.is_installment : undefined),
        (formikGantry ? formikGantry.values.data_instr : undefined)
        ,
    ]);

    /* check if any containers exist for the position */
    return (
        <>
            {
                (typeof containerAssocList !== 'undefined'
                    && typeof paramObj !== "undefined"
                    && isDataReady === true
                        ? containerAssocList.map((element: IContainerQuery) => (
                            <AfdContainer
                                key={element.sequence}
                                menuItemObj={menuItemObj}
                                userInfoObj={userInfoObj}
                                position={position}
                                controllerInfoObj={element}
                                paramObj={paramObj}
                                redirectionObj={redirectionObj}
                                formikGantry={formikGantry}

                            />
                        )) :
                        <AfdLoadingFullpage
                            componentCategory={"AfdSimpleSelector"}
                            componentInfo={"p:" + position + "_m:" + menuItemObj.id + "_u:" + userInfoObj.user_id}
                        />
                )
            }
        </>
    );
}

export default AfdSelectorSimple;
