import RestApiService from "./service/restapi/RestApiService";
import RestApiCommonService from "./service/restapi/RestApiCommonService";
import React, {useContext, useEffect, useMemo, useState, useRef } from "react";

/* Can or should I create multiple stores? Can I import my store directly, and use it in components myself?
    The original Flux pattern describes having multiple “stores” in an app, each one holding a different area of domain data.
    This can introduce issues such as needing to have one store “waitFor” another store to update.
    This is not necessary in Redux because the separation between data domains is already achieved by splitting a single reducer into smaller reducers.
    As with several other questions, it is possible to create multiple distinct Redux stores in a page, but the intended pattern is to have only a single store
        https://redux.js.org/faq/store-setup#can-or-should-i-create-multiple-stores-can-i-import-my-store-directly-and-use-it-in-components-myself
    */
import {useDispatch, useSelector} from "react-redux";


// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import CtxAppObj from "./context/CtxApp";
import CtxPusher from "./context/CtxPusher";
import IAppObj from "./type/context/IAppObj";
import IRouteMenutype from "./type/IRouteMenutype";
import IUserInfo from "./type/IUserInfo";
import {CookiesProvider, useCookies} from 'react-cookie';

// Soft UI Dashboard PRO React themes
import theme from "./assets/theme";
import {identifyConsoleStyle} from "./util/identifyConsoleStyle";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React contexts
import {CtxSoftUIGlobalSetupByRdx, useCHGetContextSoftUI} from "./context/CtxSoftUIGlobalSetupByRdx";

// Images
import AppRouter from "./AppRouter";
import AfdLoadingFullpage from "./component/AfdLoadingFullpage";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel'; // https://www.npmjs.com/package/react-facebook-pixel
import breakpoints from "./assets/theme/base/breakpoints";
import {setGeneralDispatch} from "./redux_reducer/util/dispatchShortcut";
import ICtxGlobalConfig from "./type/context/ICtxGlobalConfig";
import IRQOutputSingle from "./type/rq/IRQOutputSingle";
import {IRQOutputRouteMenutype} from "./type/rq/IRQOutputLibrary";
import IRdxState from "./type/reducer_state/IRdxState";
import RestApiAuthorizationService from "./service/restapi/RestApiAuthorizationService";
import {actUserCookieName, RDX_SWITCH_DICTIONARY} from "./redux_action/rdxAction";
import IComprofilerIndentifyResponse from "./type/comprofiler/IComprofilerIndentifyResponse";
import Pusher from "pusher-js";
import IPusherObj from "./type/context/IPusherObj";
import {debugLog} from "./util/UtilReact";
import {useCHComprofilerSessionIdentify} from "./hook/useCHComprofilerSessionIdentify";
import {UseMutationResult} from "react-query";
import useCHComprofilerSessionIdentifyRQ from "./hook/useCHComprofilerSessionIdentifyRQ";
import {useLocation  } from "react-router-dom";
import {IUtmCampaign, IUtmMedium, IUtmSource} from "./type/IUtm";
import ModalIdea from "./component/modal/ModalIdea";

{/*https://www.npmjs.com/package/react-cookie*/}

RestApiService.init();


export default function App(
    {
        userId,
        menuId,
        isDiagnostics,
        facebookAppId,
        gaTrackingId
    }: IAppObj) {

    const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
    const [internalUserId, setInternalUserId] = useState<number>(0);

    const [userInfoObj, setUserInfoObj] = useState<IUserInfo>({
        user_id: -1,
        page_set_code: '',
        get_club_promotion_boxes: '',
        bg_id: null,
        username: '',
        usertype: '',
        is_tech_account: 0,
        user_email: '',
        refresh_time: '',
        phpbb_user_id: 0,
        is_instructor: false,
        user_rank: 0,
        cb_sec_is_public_image: -1,
        cb_sec_is_course_info: -1,
        cb_sec_is_before_after: -1,
        cb_sec_is_firend_list: -1,
        cb_sec_is_future_reg: -1,
        cb_sec_is_user_spec: -1,
        cb_sec_is_image_friend_share: -1,
        cb_is_point_display: -1,
        wolacz: '',
    });
    const [routeMenutypeObjList, setRouteMenutypeObjList] = useState<IRouteMenutype[]>([]);
    const [routeAddMenutypeObjList, setRouteAddMenutypeObjList] = useState<IRouteMenutype[]>([]);
    const [pusherStoreObj, setPusherStoreObj] = useState<IPusherObj>({
        pusherObj: null,
    });
    const [ideaModalUserId, setIdeaModalUserId] = useState<number | null>(null);
    /*useEffect(() => {
            setTimeout(function () {
                setIsModalLoader(false);
            }, 3500);
        }
        , []);*/


    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();

    const [cookieJos4Name, setCookieJos4Name] = useCookies(['jos4_name']); // de facto nazwa cookie (jako typ ?? )

    /*Google Analytics: https://www.npmjs.com/package/react-ga */
    ReactGA.initialize('UA-7132871-8');

    ReactGA.pageview(window.location.pathname + window.location.search);

    /*Facebook Pixel: https://www.npmjs.com/package/react-facebook-pixel
    // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    * */
    const advancedMatching = {
        /*em: */
    };
    const facebookPixelOptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init('1487366004847224', undefined, facebookPixelOptions);


    // Typ Menu dla którego generujemy JSX Routes / SYNCH
    var routeMenutype  = 'mainmenu';


    /* !!! TO NIE JEST MIEJSCE na pakowanie zmiennych dynamicznych
    - tutaj następuje initjalizacja przy każdym RERENDER, która nadpisuje zmiany dynamiczne
    - użyj CONTEXT + STATE lub REDUX jeżeli coś zmienia się wewnętrznie
     */
    let utm_source: IUtmSource = queryHookObj.get("utm_source") as IUtmSource;
    let utm_medium: IUtmMedium = queryHookObj.get("utm_medium") as IUtmMedium;
    let utm_campaign: IUtmCampaign = queryHookObj.get("utm_campaign") as IUtmCampaign;
    let utm_content: string | null = queryHookObj.get("utm_content");
    let utm_placement: string | null = queryHookObj.get("utm_placement");



    let appObj:IAppObj = (Object.assign({
        isDiagnostics: true
    }, {
        userId: -1,
        menuId: menuId,
        isDiagnostics: isDiagnostics,
        sessionId: '',
        facebookAppId: facebookAppId,
        gaTrackingId: gaTrackingId,
        isIdeaModalLoader: ideaModalUserId,
        setIsIdeaModalLoader: setIdeaModalUserId,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        utm_content: utm_content,
        utm_placement: utm_placement,
        }
    ));


    const rdxState = useSelector((state: IRdxState) => {
        /* nie pobieraj przy ładowaniu, ale dopiero zmianie
        - kiedy zmienia się COOKIE  - idź do serwera, który określi
        * */

        return state;
    });
    const rdxDispatchFunction = useDispatch();


    /* Pobieraj SESSION_ID na samym starcie  - powinnno zwrócić przy pierwszym kontaktcie */
    useEffect(() => {
        if (!(appObj.userId  > 0)
            && !rdxState.session_id
        ) {
           /* RestApiService.getPromise('', "POST", RestApiAuthorizationService.setPostSiteIdentify()).then((response: IComprofilerIndentifyResponse) => {
                    let internalData = response.data;
                    appObj.userId = internalData.data.user_id;
                    setInternalUserId(internalData.data.user_id);

                    if (internalData.data.session_id) {
                        rdxDispatchFunction({
                            type: RDX_SWITCH_DICTIONARY.SET_SESSION_ID,
                            payload: {
                                session_id: internalData.data.session_id
                            }
                        });
                    }
                }
            )*/
        } // end of USER_ID

      /*  useCHComprofilerSessionIdentify(rdxState,
            rdxDispatchFunction,
            cookieJos4Name,
            appObj,
            setInternalUserId);*/
    }, []);

    /* Jezeli COOKIE zaktualizwane  - przelicz
     */
    useEffect(() => {
        /* Ustawiaj tylko jezeli nie ustawione oraz istnieje */
        if (typeof rdxState.cookie_name === 'undefined') {
            if (cookieJos4Name.jos4_name) {
                debugLog("%cApp.tsx IDENTIFY: RDX not exist / set to: " + cookieJos4Name.jos4_name, identifyConsoleStyle.join(";"));
                rdxDispatchFunction(actUserCookieName(cookieJos4Name.jos4_name));
            }
            debugLog("%cApp.tsx IDENTIFY: RDX not exist / after: " + cookieJos4Name.jos4_name, identifyConsoleStyle.join(";"));

            /* Nie zmieniaj jeżeli zalogowana
            - nie wylogowuj tylko dlatego, ze nie ma naszego COOKIE, ale jest JOOMLowy
            * */
            if (!internalUserId)    {
                appObj.userId = 0;
                setInternalUserId(0);
            }
        } else {

            /* BUGFIX: przy przesłaniu przez RDS state nowego COOKIE - nie jest pobierane jesszcze przez App.tsx  useCookies
            ! nie możesz bazować na useCookies
              bazu na RDX STATE
             */
            useCHComprofilerSessionIdentify(rdxState,
                rdxDispatchFunction,
                cookieJos4Name,
                appObj,
                setInternalUserId);
        }
    },
    [
        cookieJos4Name.jos4_name?.toString(),
        String(rdxState.cookie_name)
        ]
    );



    /* Get dispatchFunction so that we can pass parameters to it
       returned from CtxSoftUIGlobalSetupByRdx context
    */
    // @ts-ignore
    const [controllerParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controllerParamObj;
    const [rtlCache, setRtlCache] = useState(null);
    /*const { pathname } = useLocation();*/

    const rqUserInfo: IRQOutputSingle = RestApiCommonService.getUserInfoRQ(internalUserId);
    const rqRouteMenutype: IRQOutputRouteMenutype = RestApiCommonService.getNewControllerQueryRQ(
        'sql_query1',
        'get_site_global_menu',
        576,
        120,
        {
            user_id: 66, /* jakikolwiek USER, aby wygenerować wszystkie LINKI  - userInfoObj.user_id,*/
            bg_id: 0,
            item_id: 1
        },
        null,
        null,
        // @ts-ignore
        "parent_menu_id",
        "collapse"
    );
    const rqRouteAddMenutype: IRQOutputRouteMenutype = RestApiCommonService.getNewControllerQueryRQ(
        'sql_query2',
        'get_site_global_menu',
        576,
        120,
        {
            user_id: 66, /* jakikolwiek USER, aby wygenerować wszystkie LINKI  - userInfoObj.user_id,*/
            bg_id: 0,
            item_id: 1
        },
        null,
        null
    );

    /* Once per footer RENDER
    - musi być w useEffect -  woła w nieskończoność tworzą nowe sesje ???
    *  */
/*    let identifyMutationRQ: UseMutationResult<IComprofilerIndentifyResponse> = useCHComprofilerSessionIdentifyRQ();
    useEffect(() => {
            if (identifyMutationRQ) {
                identifyMutationRQ.mutate({
                    identifyCookieName: ''
                });
            }
        }
        , []);*/


    // @ts-ignore
    const [controlerParamObj, rdxConfigDispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const ctxSoftUIGlobalSetupByRdx: any = useContext(CtxSoftUIGlobalSetupByRdx)

    // Setting the dir attribute for the body element
    useEffect(() => {
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg &&
                (ctxSoftUIGlobalSetupByRdx[0].isMobile === false || typeof ctxSoftUIGlobalSetupByRdx[0].isMobile === 'undefined')
            ) {
                setGeneralDispatch(rdxConfigDispatchFunction, true, "IS_MOBILE");
            }
            if (window.innerWidth >= breakpoints.values.lg &&
                (ctxSoftUIGlobalSetupByRdx[0].isMobile === false || typeof ctxSoftUIGlobalSetupByRdx[0].isMobile === 'undefined')
            ) {
                setGeneralDispatch(rdxConfigDispatchFunction, false, "IS_MOBILE");
            }
        }
        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();

        /**
         The event listener that's calling the displayMobileNavbar function when
         resizing the window.
         */
        window.addEventListener("resize", displayMobileNavbar);
    }, []);


    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: "rtl",
            stylisPlugins: [rtlPlugin],
        });

        // @ts-ignore
        setRtlCache(cacheRtl);
    }, []);

    useEffect(() => {
            if (!rqUserInfo.isLoading &&
                !rqRouteMenutype.isLoading
            ) {
                /* BUGFIX: mapowanie wartości USER na boolean */
                const userInfoData: IUserInfo = {
                    user_id: rqUserInfo.data.data[0].user_id,
                    page_set_code: rqUserInfo.data.data[0].page_set_code,
                    get_club_promotion_boxes: rqUserInfo.data.data[0].get_club_promotion_boxes,
                    bg_id: rqUserInfo.data.data[0].bg_id,
                    username: rqUserInfo.data.data[0].username,
                    usertype: rqUserInfo.data.data[0].usertype,
                    is_tech_account: rqUserInfo.data.data[0].is_tech_account,
                    user_email: rqUserInfo.data.data[0].user_email,
                    refresh_time: rqUserInfo.data.data[0].refresh_time,
                    phpbb_user_id: rqUserInfo.data.data[0].phpbb_user_id,
                    is_instructor: rqUserInfo.data.data[0].is_instructor == 1 ? true : false,
                    user_rank: rqUserInfo.data.data[0].user_rank,
                    cb_sec_is_public_image: rqUserInfo.data.data[0].cb_sec_is_public_image,
                    cb_sec_is_course_info: rqUserInfo.data.data[0].cb_sec_is_course_info,
                    cb_sec_is_before_after: rqUserInfo.data.data[0].cb_sec_is_before_after,
                    cb_sec_is_firend_list: rqUserInfo.data.data[0].cb_sec_is_firend_list,
                    cb_sec_is_future_reg: rqUserInfo.data.data[0].cb_sec_is_future_reg,
                    cb_sec_is_user_spec: rqUserInfo.data.data[0].cb_sec_is_user_spec,
                    cb_sec_is_image_friend_share: rqUserInfo.data.data[0].cb_sec_is_image_friend_share,
                    cb_is_point_display: rqUserInfo.data.data[0].cb_is_point_display,
                    wolacz: rqUserInfo.data.data[0].wolacz,
                };
                setUserInfoObj(userInfoData);
                setRouteMenutypeObjList(rqRouteMenutype.data.data);
            }
    },
        [appObj.userId,
            internalUserId,
            rqRouteMenutype.isLoading ? 1 : 0,
            rqUserInfo.isLoading ? 1: 0
        ] /* możliwość przeładowania USERa */
    );
    useEffect(() => {
        if (!rqRouteAddMenutype.isLoading) {
            setRouteAddMenutypeObjList(rqRouteAddMenutype.data.data);
        }
    }
        , [rqRouteAddMenutype.isLoading ? 1 : 0]
    );

    // Setting page scroll to 0 when changing the route
/*
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        // @ts-ignore
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);
*/

    function setMouseflowScript()   {
        {/*Mouseflow with built-in single app tracking*/}

        if (userInfoObj.user_id == 0
        ) {
            // @ts-ignore
            window._mfq = window._mfq || [];
            (function () {
                var mf = document.createElement("script");
                mf.type = "text/javascript";
                mf.defer = true;
                mf.src = "//cdn.mouseflow.com/projects/6065579b-bb54-4d7e-98e3-0b694ddf1b86.js";
                document.getElementsByTagName("head")[0].appendChild(mf);
            })
            ();
        }
    }

    useEffect(() => {
        setMouseflowScript();
    }
        , [userInfoObj.user_id]
    );

    useEffect(() => {

            /*Pusher*/
            Pusher.log = function (message) {
                if (window.console && window.console) {
                    debugLog(message);
                }
            }
            let internalPusherObj = {
                pusherObj: new Pusher('09820f894bf3a53de3df', {
                    cluster: 'eu',
                })
            };
            internalPusherObj.pusherObj.connection.bind('error', function (err: any) {
                if (err.error.data.code === 4004) {
                    debugLog('PUSHER ERROR: Over limit!');
                }
            });
            setPusherStoreObj(internalPusherObj);
        },
        []
    );



    function getContainerContent() {
        return (
                <CtxAppObj.Provider value={appObj}>
                    <CtxPusher.Provider value={pusherStoreObj}>
                        <CookiesProvider>
                            <CssBaseline/>
                            {/*{layout === "vr" && <Configurator />}*/}
                            <AppRouter
                                userInfoObj={userInfoObj}
                                routeMenutypeObjList={routeMenutypeObjList}
                                routeAddMenutypeObjList={routeAddMenutypeObjList}
                            />
                        </CookiesProvider>
                    </CtxPusher.Provider>
            </CtxAppObj.Provider>
        );
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                {
                    (userInfoObj && userInfoObj.user_id > 0) && <ModalIdea
                        title={"Hello"}
                        loaderModalUserId={ideaModalUserId}
                        setLoaderModalUserId={setIdeaModalUserId}
                        handleClose={() => {
                    }}
                        modalValueObj={{
                    }}
                        isMobile={(ctxSoftUIGlobalSetupByRdx[0].isMobile === false || typeof ctxSoftUIGlobalSetupByRdx[0].isMobile === 'undefined') ? false : true}
                    />
                    }
            {
                (routeMenutypeObjList?.length > 0) ?
                    (
                        getContainerContent()
                    ) : (<>
                            <CssBaseline/>

                            <AfdLoadingFullpage
                                componentCategory="App"
                            /> </>
                    )
            }
            </ThemeProvider>
        </>
    );
}

/*

<CacheProvider value={rtlCache}>
    <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
            <>
                <Sidenav
                    color={sidenavColor}
                    brand={brand}
                    brandName="Soft UI Dashboard PRO"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {configsButton}
            </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboards/default" />} />
        </Routes>
    </ThemeProvider>
</CacheProvider>
) : (*/
