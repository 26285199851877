import React, {useState} from "react";

import Switch from "@mui/material/Switch";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import HTMLReactParser from "html-react-parser";
import parse from "html-react-parser";
import {HTMLReactParserOptions, Element} from 'html-react-parser';
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {setTextSquareTagToDiv} from "../../util/UtilReact";
import {ErrorMessage, Field} from "formik";
import Collapse from "@mui/material/Collapse";
import SoftAvatar from "../../components/SoftAvatar";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

/*https://mui.com/material-ui/react-switch/*/

function ElementJwallpapersNgFieldCheckbox(
    {
        formFieldObj,
        formikGantry,
        parserOptions,
        disabled,
    }: IFormFieldProp
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const [answersPost, setAnswersPost] = useState(false);
    const [collapse, setCollapse] = useState<number | boolean>(false);

    var formFieldTip = (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");

    /*https://www.npmjs.com/package/html-react-parser*/

    function getRolldownArrowTSX() {
        return (
            <SoftBox
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
                onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
                <SoftBox
                    flexDirection={"column"}
                    display={"flex"}
                    alignItems={"flex-start"}
                ><SoftTypography
                    component="label"
                    variant="body2"
                    fontWeight="regular"
                >
                    {formFieldObj.field_label}
                </SoftTypography>
                    <SoftTypography
                        display={"block"}
                        variant="caption"
                        sx={
                            {
                                userSelect: "none",
                                opacity: 0.9,
                            }
                        }
                        fontWeight={"regular"}

                    >
                        więcej informacji...
                        <Icon sx={{fontWeight: "bold"}} fontSize="small">
                            {collapse == 1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                        </Icon>
                    </SoftTypography>
                    <SoftBox
                        /*color={collapse == 1 ? "dark" : "text"}*/
                        pl={0.5}
                    >

                    </SoftBox>
                    <SoftBox
                        display={globalConfigParamObj.isMobile ? "block" : "flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        my={{
                            xs: 0.2,
                            sm: 0.2,
                            md: 0.2,
                            lg: 0.2,
                            xl: 0.2,
                        }}
                        ml={{xs: 1, sm: 1, md: 2, lg: 3, xl: 3}}

                    >
                        <Collapse timeout={400} in={collapse == 1}>

                            <SoftBox
                                py={0.5}
                            ><SoftTypography variant="button" fontWeight="regular" color="text">
                                {/*        {htmlToReact()}*/}
                                {parse(
                                    formFieldTip,
                                    parserOptions
                                )
                                }
                            </SoftTypography></SoftBox>


                        </Collapse>
                    </SoftBox>
                </SoftBox>


            </SoftBox>);
    }

    return (
        <>
            <Grid container
                  spacing={2}
            >
                <Grid item
                      xs={2} sm={2} md={2} lg={2} xl={2}
                >
                    {/*checked={answersPost}
                    onChange={() => setAnswersPost(!answersPost)}*/}
                    {/*!!! BUG FIX - checkbox not NECCESAIRLY touched to ERROR !!!
                        && formikGantry.touched[formFieldObj.field_name]

                         success={
                            formikGantry.values[formFieldObj.field_name] &&
                            formikGantry.values[formFieldObj.field_name].length > 0 &&
                            !formikGantry.errors[formFieldObj.field_name]}

                    */}
                    <Field
                        name={formFieldObj.field_name} as={Switch}
                        disabled={disabled}
                        error={formikGantry.errors[formFieldObj.field_name]}
                        value={"on"} // must be STRING to write to DOM
                        checked={
                          formikGantry.values[formFieldObj.field_name] &&
                          formikGantry.values[formFieldObj.field_name][0] == "on"}
                        success={
                            (formikGantry.values[formFieldObj.field_name] &&
                                formikGantry.values[formFieldObj.field_name].length > 0 &&
                                !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
                    />
                    {(formFieldObj.field_is_required === 'true') ?
                        <SoftTypography
                            display={"block"}
                            variant={"h5"} color={"error"}
                            sx={{
                                clear: "both",
                            }}
                        >
                            &nbsp;*
                        </SoftTypography>
                        : null
                    }
                    <SoftTypography variant={"caption"} color={"error"}>
                        <ErrorMessage name={formFieldObj.field_name}/>
                    </SoftTypography>

                    {/*<Switch
                        name={formFieldObj.field_name}
                        id={formFieldObj.field_name}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />*/}
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}
                >{getRolldownArrowTSX()}</Grid>
            </Grid>
        </>
    );
}


export default ElementJwallpapersNgFieldCheckbox;
