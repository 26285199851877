import React from 'react';
import axios from "axios";
import RestApiService from "./RestApiService";
import RestApiAmocourseService from "./RestApiAmocourseService";

import {API_URL} from "./RestApiService";
import {useQuery} from "react-query";
import IRQOutputSingle from "../../type/rq/IRQOutputSingle";
import IRQOutputList from "../../type/rq/IRQOutputList";
import {rqOptionStaticObj} from "../rqOptionStaticObj"
import IRQQueryKey from "../../type/rq/IRQQueryKey";
import {IRQOutputGlobalFont, IRQOutputParamRegProvider} from "../../type/rq/IRQOutputLibrary";
import IAxiosPostData from "../../type/axios/IAxiosPostData";
import {bin2hex} from "../../util/Util";
import linkDictionary from "../../linkDictionary";
//"Access-Control-Request-Method": 'GET',
//"Access-Control-Request-Headers": 'Origin,X-Requested-With,Content-Type,Accept',
const headers = {
    "Content-Type": 'text/html'
};

//?function_name=get_site_opinion_table&page_item_id=206&sequence=80&object_id=&object2_id=-1&limit_from=0&day_ago_from=0

class RestApiBackendService extends RestApiService {

    constructor() {
        super();
    }

    static setBackendMarketingIexpImageInsert(
        postObj: any,
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: postObj,
        };
        return postDataObj;
    }

    static setBackendPretentReviewInsert(postObj: any): IAxiosPostData {
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: postObj,
        };
        return postDataObj;
    }




}

export default RestApiBackendService;
